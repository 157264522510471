<script setup lang="ts">
import type { LeaderboardEntryDto } from "~~/_api_generated_";
import { truncateString } from "~/utils/stringHelpers";

interface Props {
  members: Array<LeaderboardEntryDto>;
  campaignName: string;
  layout?: string;
}
const { members, campaignName, layout = "default" } = defineProps<Props>();

function getRowClassForMember(member: LeaderboardEntryDto, index: number) {
  let rowClass = "";

  if (member?.isLoggedUser) {
    rowClass = "bg-black text-white";
  } else if (index == 0) {
    rowClass = "bg-purple-light";

    if (layout === "paycheck") {
      rowClass = "bg-white text-black";
    }
  } else if (index == 1) {
    rowClass = "bg-grey-light";

    if (layout === "paycheck") {
      rowClass = "bg-black/20 text-black";
    }
  }

  return rowClass;
}
</script>

<template>
  <div>
    <div class="text-lg mb-6 pr-10 lg:pr-0">
      {{ members.length }} eligible creators
    </div>

    <div class="text-gray-400 my-6">
      Posts may have been created organically or by participating in
      {{ campaignName }}
    </div>

    <div class="flex flex-col gap-1">
      <div
        v-for="(member, memberIndex) in members"
        :key="memberIndex"
        class="flex items-center justify-between p-1.5 px-3 rounded-lg gap-2"
        :class="getRowClassForMember(member, memberIndex)"
      >
        <div class="flex items-center gap-2">
          <span class="w-4 text-right">{{ member.rank }}</span>
          <img
            :src="member.userProfilePictureUrl"
            class="w-6 aspect-square rounded-full"
            alt=""
          />
          <div class="">
            @{{ truncateString(member.userTwitterHandle, 20) }}
          </div>
        </div>
        <div class="flex items-center gap-2 shrink-0">
          <div v-if="memberIndex == 0" class="text-black/50 hidden lg:block">
            Impact Share
          </div>
          <div>
            {{
              parseFloat(String(member.relativeImpactPercent) ?? "0").toFixed(
                2
              )
            }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
