<script setup lang="ts">
import type { LeaderboardEntryDto } from "~~/_api_generated_";
import LeaderBoardSIdebar from "~/components/Campaign/LeaderBoardSIdebar.vue";

interface Props {
  members: Array<LeaderboardEntryDto>;
  campaignName: string;
  creatorNumber?: number;
  showTitle?: boolean;
  showFooter?: boolean;
  layout?: string;
  showShares?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  layout: "default",
  creatorNumber: 10,
  showShares: true,
  showTitle: true,
  showFooter: true,
});

const creatorNumber = computed(() => {
  return props.creatorNumber;
});

const leaderboardSidebarVisible = ref(false);

const getTopMembersWithYou = computed(() => {
  // Get the first 5 members

  let topMembers = removeReactivity(props.members).slice(
    0,
    creatorNumber.value,
  );

  // Find the index of "you" in the original array
  const currentLoggedUserIndex = props.members.findIndex(
    (member) => member?.isLoggedUser,
  );

  if (currentLoggedUserIndex >= creatorNumber.value) {
    const restNum = creatorNumber.value - 1;
    topMembers = topMembers = removeReactivity(props.members).slice(0, restNum);

    const currentLoggedUserMember = removeReactivity(
      props.members[currentLoggedUserIndex],
    );

    // Append "you" as the 6th member with the correct place number
    topMembers.push({
      ...currentLoggedUserMember,
      rank: currentLoggedUserIndex + 1, // The place number is your original position + 1
    });
  }

  return addMemberStyles(topMembers);
});

const hiddenMembersCount = computed(() => {
  const rest = props.members.length - creatorNumber.value;

  if (rest <= 0) return 0;

  return rest;
});
</script>

<template>
  <div
    v-if="!members.length"
    class="flex items-center justify-center h-full"
  ></div>
  <div v-else>
    <Sidebar
      v-if="leaderboardSidebarVisible"
      @close="leaderboardSidebarVisible = false"
    >
      <LeaderBoardSIdebar :members="members" :campaign-name="campaignName" />
    </Sidebar>

    <div v-if="showTitle" class="text-lg mb-6">
      {{ members.length }} Creators
    </div>

    <div class="flex flex-col gap-1 font-semibold">
      <div
        v-for="(member, memberIndex) in getTopMembersWithYou"
        :key="memberIndex"
        class="flex items-center justify-between p-1.5 px-3 rounded-lg"
        :class="member.rowClass"
      >
        <div class="flex items-center gap-4">
          <span class="w-4 text-right">{{ member.rank }}</span>
          <img
            :src="member.userProfilePictureUrl"
            class="w-6 aspect-square rounded-full"
            alt=""
          />
          <div class="max-w-28 truncate lg:max-w-none">
            <p v-if="member.isLoggedUser">You</p>
            <p v-else>@{{ member.userTwitterHandle }}</p>
          </div>
        </div>
        <div v-if="showShares" class="flex items-center gap-2">
          <div v-if="memberIndex == 0" class="text-black/50 hidden lg:block">
            Impact Share
          </div>
          <div>
            {{
              parseFloat(String(member.relativeImpactPercent) ?? "0").toFixed(
                2,
              )
            }}%
          </div>
        </div>
      </div>

      <div
        v-if="showFooter"
        class="flex items-center gap-4 justify-between p-1 px-3 rounded-2xl"
      >
        <div v-if="hiddenMembersCount" class="flex items-center gap-4">
          <span class="w-4 text-right">+{{ hiddenMembersCount }}</span>
          <div class="w-6 h-auto"></div>
          <div class="text-black/50">Others</div>
        </div>
        <div v-else></div>
        <div class="flex">
          <button
            class="text-purple cursor-pointer"
            @click="leaderboardSidebarVisible = true"
          >
            See Leaderboard
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
